import './App.css';

import React from 'react';

import {BrowserRouter as Router, Link as RouterLink, Route, Switch} from 'react-router-dom';

import {DataGrid} from '@material-ui/data-grid';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

let apiRoute = "https://api.eebot.org/recipe"

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		minWidth: '100vw',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	content: {
		flexGrow: 1,
	},
	footer: {
		padding: theme.spacing(3, 2),
		marginTop: 'auto',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
	},
}));

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary">
			<Link color="inherit" href="https://eebot.org/">
				eebot.org
			</Link>
			{' Copyright © '}
			{new Date().getFullYear()}
			{' - Joss Steward & Jared Good '}
		</Typography>
	);
}

export default function App() {
	const classes = useStyles();

	const preventDefault = (event) => event.preventDefault();

	return (
		<Router>
			<div className={classes.root}>
				<Container width={1} height="100%">
					<CssBaseline/>
					<AppBar position="fixed">
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
								<Link component={RouterLink} to="/" color="inherit">
									EEBot.org - Buyback Price Calculator
								</Link>
							</Typography>
						</Toolbar>
					</AppBar>
					<Toolbar/>
					<Switch>
						<Route path="/recipe/:recipeId" component={Recipe}/>
						<Route path="/">
							<Box xs={12} mt={2}>
								<Paper className={classes.paper}>Flying Tractors LTD has the best Eve Echoes prices around, see below!</Paper>
							</Box>
							<Box xs={12} mt={2}>
								<Paper className={classes.paper}>
									<Home/>
								</Paper>
							</Box>
						</Route>
					</Switch>
				</Container>

				<footer className={classes.footer}>
					<Container maxWidth="sm">
						<Copyright/>
					</Container>
				</footer>
			</div>
		</Router>
	);
}

const columns = [
	{
		field: 'output', headerName: 'Name', flex: .6,
		renderCell: (params) => (
			<span>
				<strong>
					<Button
						variant="contained"
						color="primary"
						size="small"
						style={{marginLeft: 16, marginRight: 8}}
						to={"/recipe/" + params.value}
						component={RouterLink}
					>
						Get Price
					</Button>
				</strong>
				{params.value}
			</span>
		),
	},
	{field: 'type', headerName: 'Type', flex: 0.20},
	{field: 'tech_level', headerName: 'Tech Level', flex: 0.20},
];

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			isLoaded: false,
			recipes: [],
			searchWord: ""
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		if (!this.state.isLoaded) {
			fetch(apiRoute)
				.then(result => result.json())
				.then(
					(result) => {
						result.content.forEach(function (e) {
							e.id = e.output;
						})

						this.setState({
							isLoaded: true,
							recipes: result.content,
							error: null
						});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						console.log(error);
						this.setState({
							isLoaded: true,
							error: error
						});
					}
				)
		}
	}

	handleChange(event) {
		this.setState({searchWord: event.target.value});
	}

	render() {
		document.title = "eebot.org - All Items";

		if (!this.state.isLoaded) {
			return <div><p>Loading...</p></div>
		}

		const {searchWord, recipes} = this.state;

		return (
			<div>
				<div className="form">
					<Search handleChange={this.handleChange} searchWord={searchWord}/>
				</div>
				<Box style={{height: "65vh", width: '100%'}} mt={1}>
					<DataGrid rows={recipes} columns={columns}
					          filterModel={{
						          items: [{columnField: 'output', operatorValue: 'contains', value: searchWord}]
					          }}
					/>
				</Box>
			</div>
		);
	}
}

class Search extends React.Component {
	render() {
		return (
			<FormControl fullWidth noValidate autoComplete="off" id="searchDiv" width={1}>
				<TextField
					width={1}
					id="searchBar"
					type="text"
					label="Search"
					value={this.props.searchWord}
					name="searchWord"
					onChange={this.props.handleChange}
				/>
			</FormControl>
		);
	}
}

class Recipe extends React.Component {
	constructor(props) {
		super(props);

		let {recipeId} = this.props.match.params;

		this.state = {
			error: null,
			isLoaded: false,
			recipeId: recipeId,
			recipeType: "",
			recipeTechLevel: "",
			recipePrice: ""
		};
	}

	componentDidMount() {
		if (!this.state.isLoaded) {
			let path = encodeURI(apiRoute + "/" + this.state.recipeId);

			fetch(path)
				.then(result => result.json())
				.then(
					(result) => {
						this.setState({
							isLoaded: true,
							recipeId: this.state.recipeId,
							recipeType: result.content.type,
							recipeTechLevel: result.content.tech_level,
							recipePrice: result.content.price,
							error: null
						});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						console.log(error);
						this.setState({
							isLoaded: true,
							error: error
						});
					}
				)
		}
	}

	render() {
		document.title = "eebot.org - " + this.state.recipeId + " price";

		if (!this.state.isLoaded) {
			return (
				<div>
					<h2>Loading {this.state.recipeId}...</h2>
				</div>
			)
		}
		return (
			<Box xs={6} mt={2}>
				<Card alignContent={"left"} textAlign={"left"} xs={6} mt={2}>
					<CardContent>
						<Typography variant="h4" gutterBottom>{this.state.recipeId} </Typography>
						<Typography variant="body1" gutterBottom>Type: {this.state.recipeType}</Typography>
						<Typography variant="body1" gutterBottom>Tech Level: {this.state.recipeTechLevel}</Typography>
						<Typography variant="body1"
						            gutterBottom>Price: {parseFloat(this.state.recipePrice).toLocaleString()} Isk</Typography>
					</CardContent>
				</Card>
			</Box>
		);
	}
}